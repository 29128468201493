export default {
  /** ----------------------------------------------------
   *                        ANCV
   *  ---------------------------------------------------- */
  title_pay_with_ancv: "Pay with your Chèque-Vacances Connect account",
  try_again: "The Chèque-Vacances payment failed. Please try again.",
  important: "Important: ",
  ancv_app_mandatory:
    "the Chèque-Vacances application is required to validate this payment",
  ancv_id: "Your Cheque-Vacances Connect login",
  open_ancv_app:
    "Open your ANCV Chèque-Vacances application to finalize the payment process.",
  transaction_success: "Your transaction has been successfully recorded.",
  transaction_infos_below:
    "You will find below the information related to your payment.",
  see_you: "See you soon,",
  ancv_signature: "The Chèque-Vacances team.",
  ancv_amount: "Amount received",
  total_amount: "Amount requested",
  ancv_transaction_id: "Transaction reference",
  remaining_amount: "Remaining amount due",
  excluding_fees: "excluding financing fees",
  installment_cta: "Pay remaining amount due in {installments_nb}x by credit card",
  deferred_cta: "Pay the rest later by credit card",
  infos_ancv_and_bnpl:
    "Once the amount to be paid in Chèque-Vacances has been validated using the Chèque-Vacances application, a payment schedule in several installments based on the remaining amount to be paid by credit card will be presented to you.",
  warning_delay_bnpl:
    "You have {delayInMinutes} minutes to finalize your payment. After this time, the payment by Chèque-Vacances and your reservation will be cancelled.",
  /** ----------------------------------------------------
   *                        CHECKOUT
   *  ---------------------------------------------------- */
  input_bank_details: "I enter my bank details",
  amount_to_pay: "Amount to be paid",
  total_purchase_amount: "Total amount",
  split_explanation: "If all participants pay within 48 hours, I'll pay ",
  i_pay_my_share: "I pay my share",
  i_pay_my_installment: "I pay my installment",
  anticipated_payment_warning:
    "You are preparing to pay the <b>{date}</b> due date, i.e. <b>{days} days</b> before the date initially agreed",
  i_change_my_payment_method: "I update my payment method",
  payment_method_fingerprint_amount:
    "A credit card imprint of {amount} may appear on your account. This is temporary and no amount is withdrawn.",
  card_number: "Card number",
  expiration_date: "Expiry Date",
  security_code: "Security code",
  card_network: "Card Network",
  notice_payment: "instructions for use of the payment service",
  accept_cta_installment:
    "By clicking on Validate, I accept {0}. Finally, I certify that the card used to pay for my purchase is linked to an account opened in my name.",
  split_default_message:
    "I will receive a request for authorization of the whole amount. No amount will be debited. My friends have 48 hours to pay. After this period, if one of them does not pay, I will be debited from his share.",
  secured_payment: "Secured payment",
  deadline_on: "deadline on",
  with_fees_amount: "(including {amount} of fees)",
  basket_amount: "Basket amount:",
  ill_pay_later: "I'll pay on {date}",
  ill_pay_later_on_delivery: "I will pay on delivery (and no later than {date})",
  penalties_detail: "including {penalties_percentage}% late payment fees : {amount}",
  not_supported_cards:
    "Prepaid cards like Electron, Maestro, Compte Nickel, Revolut, and ecards are not accepted.",
  payment_method_issue:
    "An error has occurred when using your payment method. Please contact the merchant's support team.",
  order_info_multiple_shipment:
    "The order can be composed of several shipments. Each shipment will have its own schedule that will start at the time of shipment.",
  deposit_deadline_on: "Deposit deadline on",
  balance_deadline_on: "Balance deadline on",
  balance_to_pay_later: "I will pay the balance on the {date}",
  test_another_card: "Try another card.",
  entry_error: "Entry error.",
  card_declined: "Your card has been declined.",
  invalid_iban: "Your iban is incorrect.",
  stripe_library_not_loaded: "Stripe V3 library not loaded!",
  taeg: "You take out a {num_of_days}-day payment facility, which will incur {amount} in fees, i.e. an APR of {taeg}%.",
  amount_paid_in_ancv: "Amount paid in Chèque-Vacances",
  advertisement_optin:
    "I wish to receive commercial communications from {optin_advertiser}",
  electronic_optin:
    "I wish to receive electronic commercial communication from CA Consumer Finance",
  phone_mail_optout:
    "I do not wish to receive phone or mail commercial communication from CA Consumer Finance",
  contract_message_start: "I accept the ",
  contract_message_link_installment:
    "pre-contractual and contractual information on payment in installments ",
  contract_message_link_deferred:
    "pre-contractual and contractual information on deferred payment ",
  contract_message_end:
    "and I certify that the card used to pay for my purchase is linked to an account opened in my name.",
  /** ----------------------------------------------------
   *                        COMMONS
   *  ---------------------------------------------------- */
  add: "Add",
  me: "Me",
  friend: "Friend",
  submit: "Submit",
  yes: "Yes",
  no: "No",
  update: "Update",
  apply: "Apply",
  see_faq: "See our FAQ for more information",
  language: "Language:",
  back_to_website: "Back to {website}",
  unknown: "Unknown",
  on_date: "On {date}",
  footer_question_to_merchant:
    "For any inquiries related to your purchase, please contact {merchantName} directly.",
  footer_question_to_pledg:
    'For any information relating to your financing, consult our <a href="{faqUrl}" target="_blank">FAQ</a> or write to us at <a href="mailto:{contactEmail}"> {contactEmail}</a>.',
  notice_access_link: "Notice of use of the payment service",
  data_policy_link: "<a href='{dataPolicyUrl}' target='_blank'>Data Privacy Policy</a>",
  data_policy:
    "My details will be used in a fully automated process to grant a payment facility: <a href='{dataPolicyUrl}' target='_blank'>data privacy policy</a>.",
  contact_access_link:
    "<a href='{contactUrl}' target='_blank'>Contact payment service</a>",
  no_information: "No information provided",
  no_psp_source: "No payment method available",
  redirected_from_message:
    "{merchantName} has redirected you to {operatorTeamName} to complete your payment.",
  your_purchase: "Your purchase",
  nationality_short: "Nationality",
  birth_last_name_short: "Birth name",
  email_address: "Email address",
  full_address: "Mailing Address",
  birthdate: "Birth date",
  birthplace: "Place of birth",
  firstname: "First name",
  lastname: "Name",
  phone_number_short: "Phone number",
  /** ----------------------------------------------------
   *                    DASHBOARD BUYER
   *  ---------------------------------------------------- */
  notice_infos: "Please consult the payment service notice",
  error_invalid_contact: "Contact is invalid",
  fees_amount: "(Fees: {amount} {currency})",
  here: "here",
  deferred_intro:
    "Status of your deferred purchase from {date} at {hour} ({timezone_city} time) on {merchantName}.",
  installment_intro:
    "Status of your split purchase from {date} at {hour} ({timezone_city} time) on {merchantName}.",
  split_intro:
    "Status of your shared purchase from {date} at {hour} ({timezone_city} time) on {merchantName}.",
  penalties_amount: "(Penalties: {amount} {currency})",
  proceed_payment_late: "Regularize my situation: {amount} {currency}",
  proceed_payment_in_advance: "Pay my next installment: {amount} {currency}",
  dashboard_buyer_your_basket: "Your basket",
  dashboard_buyer_your_fees: "Finance Fee ({feesPercentage}%)",
  dashboard_buyer_payment_schedule: "Your due dates",
  dashboard_buyer_products: "Your products",
  dashboard_buyer_see_schedule: "See schedule",
  dashboard_buyer_overdue: "Default in payment",
  dashboard_buyer_overdue_payment: "Regularization of your situation",
  dashboard_buyer_overdue_payment_text:
    "The payment for the due date of {date} has failed. Please proceed to regularize your situation.",
  dashboard_buyer_your_payment_method: "Your payment method",
  dashboard_buyer_your_payment_method_expired_soon:
    "Your payment method is about to expire. Update it to avoid any payment incidents.",
  dashboard_buyer_your_payment_method_text_installment:
    "Debits for your monthly installments are made on the agreed dates using the following payment method:",
  dashboard_buyer_your_payment_method_text_deferred:
    "The amount due will be collected on the agreed date, using the following payment method:",
  dashboard_buyer_your_payment_method_button: "Change my payment method",
  dashboard_buyer_share_captured_amount: "Paid:",
  dashboard_buyer_share_not_charged_amount: "Canceled:",
  dashboard_buyer_share_refunded_amount: "Refunded:",
  dashboard_buyer_share_outstanding_amount: "Outstanding amount:",
  dashboard_buyer_title_installment:
    'Your <span class="text-secondary">payment in {installmentsNb} times</span> at {merchantName}',
  dashboard_buyer_title_deferred:
    'Your <span class="text-secondary">deferred payment</span> at {merchantName}',
  dashboard_buyer_title_down_payment:
    'Your <span class="text-secondary">payment with deposit</span> at {merchantName}',
  dashboard_buyer_title_split:
    'Your <span class="text-secondary">shared payment</span> at {merchantName}',
  dashboard_buyer_status_total_refund:
    "Your purchase has been refunded in accordance with the terms and conditions of {merchantName} and the payment service.",
  dashboard_buyer_status_split_waiting_payment:
    "Not all participants have paid their share.",
  dashboard_buyer_status_fully_paid:
    "Your purchase has been fully paid. See you soon at {merchantName}.",
  dashboard_buyer_status_overdue:
    "Payment of an installment has failed. Regularize your situation or contact the payment service as soon as possible.",
  dashboard_buyer_status_ongoing:
    "The payment of your purchase takes place normally, no action on your part is required.",
  dashboard_buyer_status_psp_source_expired:
    "Your payment method is expiring. Update it to continue paying your installments.",
  resend_message: "Resend Message",
  split_leader_label: "You",
  state_leader_not_paid: "Pending",
  state_expired_not_paid: "Payment failed, to be regularized",
  state_future_not_paid: "Automatic debit on due date",
  state_preauthorization_ok: "Bank imprint",
  state_sepa_debit_pending: "SEPA debit pending validation",
  state_sofort_pending: "Sofort payment pending",
  state_ancv_pending_transfer: "Awaiting validation",
  state_ancv_processing: "Awaiting payment by Chèques-Vacances",
  state_ancv_preauthorization_ok: "Paid by Chèques-Vacances",
  state_payment_ok: "Paid",
  state_paid_by_leader: "Paid by you",
  state_unknown: "Awaiting payment",
  state_fully_refunded: "Refunded",
  state_fully_not_charged: "Canceled",
  split_explanation_1:
    "Each participant has until {date} at {hour} ({timezone_city} time) to pay for their share. After this time, you will be charged the amount of your split ({amount} {currency}) as well as unpaid shares.",
  dashboard_order_link: "Back to the order dashboard",
  header_amount: "Amount",
  header_participants: "Participants",
  header_status: "Status",
  error_dashboard_title: "Dashboard unavailable",
  error_dashboard_subtitle: "Your purchase summary could not be displayed.",
  error_dashboard_reason_title: "For what reason?",
  error_dashboard_reason_1: "The purchase in question may not be available",
  error_dashboard_reason_1_details:
    "This dashboard is not available for purchases being created or cancelled.",
  error_dashboard_reason_2: "This page may have expired",
  error_dashboard_reason_2_details:
    "For security reasons, this page is subject to an expiration. Please close the page and click on the original link again.",
  /** ----------------------------------------------------
   *                        ERRORS
   *  ---------------------------------------------------- */
  purchaseNotFound: "Could not find a purchase associated with this URL.",
  genericError:
    "An error occured. If it happens again, please contact us on contact{'@'}pledg.co.",
  error_infra_unavailable:
    "The service is currently unavailable. Please reload the page or try again later.",
  notAddedValidDestinationWarning:
    "Please click on the'+ Add' button to add your friend.",
  invalidDestination: "This email/mobile number is invalid!",
  alreadyUsedDestination: "This email/mobile number is already used!",
  invalidAmount: "This amount is not valid!",
  closingWarning: "Do you really want to close that window?",
  singleShareWarning: "Do you really want to pay alone?",
  Confirmation3DSTimeoutError: "Authentication failed. Please try again.",
  ancvConfirmationTimeoutError:
    "Error when paying with Chèques-Vacances. Please try again.",
  requiredPropertyMissingOrInvalid:
    "The required property {property} is missing or invalid",
  requiredPropertyMissing: "The required property {property} is missing",
  propertyInvalid: "The provided property {property} is invalid",
  paymentRefused: "Payment has been refused",
  errorHappened:
    "<strong>Your request for the payment facility has been declined. Please use an alternative payment method.</strong>",
  errorAmountHold:
    "Depending on your bank, your account may be debited. This is temporary and no amount is debited from your account.",
  retry_error: "Request for payment facility declined.",
  ineligibilityPaymentMethod: "Please use another payment method",
  ineligibilityPaymentMethodRedirect:
    "You will be redirected to choose another payment method.",
  ineligibilityPaymentMethodRedirectWithMerchant:
    "You will be redirected to the {merchantName} website to choose another payment method.",
  ineligibilityErrorContentWithMerchant:
    "Based on the information provided by {merchantName} or collected directly by the payment provider and following a fully automated decision process, your application does not meet the eligibility criteria for this payment facility. This decision does not prejudge the outcome of any future applications for funding and you can contact us by writing to {infoEmail} to find out more.",
  ineligibilityErrorContent:
    "Based on information collected directly by the payment provider and following a fully automated decision process, your application does not meet the eligibility criteria for this payment facility. This decision does not prejudge the outcome of any future applications for funding and you can contact us by writing to {infoEmail} to find out more.",
  provideMerchantUid: "Please provide a merchant uid",
  invalidPhoneNumber: "This phone number is not valid.",
  IdentificationConfirmationTimeoutError:
    "An error occured during the verification process. If it happens again, please contact us on {contact_email}.",
  splitIncorrectAmounts: "The amounts entered appear to be incorrect",
  fipenDownloadError:
    "An error occurred while retrieving your pre-contractual information. Please try again later.",
  contractDownloadError:
    "An error occurred while retrieving your contractual information. Please try again later.",
  contractSignError:
    "An error occurred while signing your contract. Please try again later.",
  /** ----------------------------------------------------
   *                    STEPS TITLE
   *  ---------------------------------------------------- */
  title_payment_missing_info: "I complete my <span>billing information</span>",
  title_payment_solutions: "I choose my <span>payment plan</span>",
  title_missing_info_installment:
    "I pay for my purchase <span>in {installmentsNb} installments</span>",
  title_missing_info_deferred: "I pay for my purchase <span>later</span>",
  title_missing_info_down_payment: "I pay for my purchase <span>with a deposit</span>",
  title_missing_info_split: "I pay for my <span>shared purchase</span>",
  title_otp: "I confirm my <span>phone</span>",
  title_revenue_collection: "I declare my income and <span>expenses</span>",
  title_confirm_info: "I confirm my <span>information</span>",
  title_payment: "I enter my <span>payment method</span>",
  title_processing: "I let {operatorTeamName} verify my <span>creditworthiness</span>",
  /** ----------------------------------------------------
   *                        IDENTIFICATION
   *  ---------------------------------------------------- */
  identity_check: "Identity verification",
  identification_success: "The verification was successful",
  identification_failure: "The verification has failed",
  identification_processing: "Processing verification",
  identity_check_warning_message:
    "You are about to be redirected to verify your identity.",
  /** ----------------------------------------------------
   *                    INFORMATION MISSING
   *  ---------------------------------------------------- */
  address_street: "Street",
  address_zipcode: "Zipcode (e.g., SW1A 1AA)",
  birth_address_zipcode: "Birth Zipcode (e.g., SW1A 1AA)",
  address_city: "City (e.g., London)",
  civility: "Civility (as stated on your ID)",
  birth_last_name: "Birth name (e.g., Smith)",
  madam: "Madam",
  mister: "Mister",
  address_province: "State province",
  address_country: "Country (e.g., United Kingdom)",
  birth_date_placeholder: "Birth date (mm/dd/yyyy)",
  birth_city: "Birth city",
  birth_country: "Birth country",
  search_birthplace: "Birthplace (e.g., SW1A 1AA London, United Kingdom)",
  birth_place_autocomplete_error: "Please select a birthplace from the list",
  nationality: "Nationality",
  phone_number: "Phone Number (e.g., +447123456789)",
  missing_information_title: "We need a few more details",
  missing_information_subtitle:
    "To proceed with your financing request, we need more information. ",
  missing_information_subtitle_payer: "Enter your billing information",
  email: "e-mail",
  day: "Day",
  month: "Month",
  year: "Year",
  fipen_text: "You can now download ",
  fipen_link: "your pre-contractual information.",
  /** ----------------------------------------------------
   *                    REVENUE
   *  ---------------------------------------------------- */
  title_revenue: "We need some additional information",
  revenue_explanation:
    "In order to be sure of your borrowing capacity, we need information about your income and your monthly expenses.",
  declared_revenue: "After-tax incomes",
  declared_expenditure: "Total charges",
  declared_csp: "Professional category",
  tooltip_revenue: "Net salary, financial support, real estate income...",
  tooltip_expenditure: "Current credits, rent, alimony",
  back_to_merchant: "I understand, take me back to the merchant",
  professional_categories: [
    "Executives managers",
    "Intellectual and scientific professions",
    "Intermediate salaried professions",
    "Small entrepreneurs (non-employees)",
    "Skilled employees",
    "Salaried skilled workers",
    "Low-skilled salaried professions",
    "Retired",
    "Other unemployed people",
  ],
  /** ----------------------------------------------------
   *                    SUMMARY
   *  ---------------------------------------------------- */
  title_summary: "Summary of your dossier",
  summary_explanation: "Before signing your contract, please confirm your details.",
  summary_unchangeable: "Not modifiable",
  summary_your_financing: "Your financing",
  summary_your_identity: "Your identity",
  summary_your_revenue: "Your finances",
  summary_your_contact_details: "Your contact details",
  summary_purchase_amount: "Purchase amount",
  summary_deposit_amount: "Amount of deposit",
  summary_financed_amount: "Amount financed",
  summary_total_amount_due: "Total amount due",
  summary_monthly_installments: "{installments_nb} monthly installments of",
  summary_fixed_borrowing_rate: "Fixed borrowing rate",
  summary_net_monthly_income: "Net monthly income",
  summary_recurring_expenses: "Recurring expenses",
  summary_csp: "Professional category",
  summary_acknowledgement_statement:
    "I acknowledge that I have read the standardised European pre-contractual information sheet. I certify on my honour that the above information is correct, declare that I do not have a current over-indebtedness plan and declare that the loan requested is not for the purpose of consolidating credit",
  summary_terms_and_conditions:
    "I acknowledge having read and accepted the general terms and conditions of digital services",
  /** ----------------------------------------------------
   *                    INFORMATION SPLIT
   *  ---------------------------------------------------- */
  title_whodoisharewith: "Who are the friends I share my order with?",
  subtitle_addfriends: "I add the email adresses or the mobile numbers of my friends",
  placeholder_friend_email_phone_number: "My friend's email or mobile number",
  attendees: "No co-buyer | 1 co-buyer | {count} co-buyers",
  pay_alone: "I want to pay on my own",
  /** ----------------------------------------------------
   *                        PAYMENT SOLUTIONS
   *  ---------------------------------------------------- */
  payment_solutions_title: "Available payment facilities",
  payment_solutions_subtitle: "with {company}",
  payment_solutions_choice_infos:
    "Please choose the payment facility you would like to use",
  payment_schedule_installment_homogeneous: "{installments_nb}x {amount}",
  payment_schedule_installment_disparate:
    "{amount} then {installments_nb}x {second_amount}",
  payment_schedule_deferred_delay_in_days: "{amount} in {delay_in_days} days",
  payment_schedule_deferred_day_of_next_month: "{amount} on {payment_date}",
  payment_solutions_no_merchants_found:
    "No payment solution meets the eligibility criteria based on the information provided",
  /** ----------------------------------------------------
   *                        PROCESSING
   *  ---------------------------------------------------- */
  payment_pending: "Payment pending",
  payment_pending_information_1:
    "Please do not refresh the page while information is being processed.",
  payment_pending_information_2: "This step may take a few minutes.",
  payment_cancelled: "Payment canceled",
  payment_analysis_needed: "You are almost there!",
  payment_analysis_information_1:
    "As part of your financing application, we would like to obtain a 90-day transaction history from your bank.",
  payment_analysis_information_2:
    "This service is operated by our partner {providerLabel}, a leader in the secure sharing of banking data, approved by the ACPR.",
  payment_analysis_information_3:
    "By clicking on Validate, I agree to send {operatorTeamName} the information extracted from my bank statements",
  payment_analysis_information_4:
    "If you do not wish to do so, you can return to the selection of payment methods for {merchantName} by clicking on the cross.",
  payment_analysis_in_progress: "Analysis in progress",
  payment_analysis_progress_information_1:
    "Your request for a payment facility is being analyzed.",
  payment_analysis_progress_information_2:
    "Please do not refresh the page while information is being processed. This step may take a few minutes.",
  /** ----------------------------------------------------
   *                        SUCCESS
   *  ---------------------------------------------------- */
  congratulations: "Congratulations!",
  payment_registered: "Your payment has been registered.",
  payment_method_registered: "Your new payment method has been taken into account.",
  /** ----------------------------------------------------
   *                        TWO-FA
   *  ---------------------------------------------------- */
  phone_number_check: "Checking your phone number",
  phone_number_tooltip: "This is the phone number you provided to the merchant",
  wrong_code: "Wrong code, please try again",
  unknown_error: "An unknown error occured",
  code_valid: "Code is valid",
  please_type_code: "Type this code in the field below",
  you_ll_receive_text: "We have just sent you a code to ",
  did_not_receive: "I did not receive",
  send_again: "send again",
  sending_sms: "Code transmission in progress...",
  verify: "Verify",
  code_transmission_error:
    "An error occured while sending the code. Pleasy try again later",
  /** ----------------------------------------------------
   *                        VALIDATIONS
   *  ---------------------------------------------------- */
  validations: {
    required: "The field is required",
    phone_number: "The phone number is not valid",
    date_format: "The date format is not valid ({_value_})",
    invalid_birthdate: "Please enter a valid date of birth",
    date_between: "The date is invalid",
    min: "The field must contain at least {length} characters.",
    positive: "The value must be a positive number.",
    email: "The email format is invalid ({_value_})",
  },
  /** ----------------------------------------------------
   *                        CUSTOM SCREEN
   *  ---------------------------------------------------- */
  custom_success_screen: {
    title: 'Your booking is not complete until you click "Buy Now"',
    subtitle:
      "Once your booking is complete, your trip will be paid in full and you'll be ready to travel.",
    text: "A pre-authorization might appear on your bank account. We will notify you before each payment is due. Missed payments may result in trip cancellation.",
  },
  /** ----------------------------------------------------
   *                        ERROR SCREEN
   *  ---------------------------------------------------- */
  error_screen: {
    error_410: "The requested resource is no longer available",
    error_404: "Error 404",
    expired: "The requested URL has expired",
    not_found: "The page you are looking for was not found",
  },
};
