export default {
  /** ----------------------------------------------------
   *                        ANCV
   *  ---------------------------------------------------- */
  title_pay_with_ancv: "Pague com a sua conta Chèque-Vacances Connect",
  try_again:
    "O pagamento através da Chèque-Vacances falhou. Por favor, tente novamente",
  important: "Importante : ",
  ancv_app_mandatory:
    "A aplicação Chèque-Vacances é necessária para validar este pagamento",
  ancv_id: "O seu Chèque-Vacances Connect ID",
  open_ancv_app:
    "Abra a sua aplicação ANCV Chèque-Vacances para completar o processo de pagamento",
  transaction_success: "A sua transacção foi registada com sucesso",
  transaction_infos_below:
    "Encontrará abaixo as informações relacionadas com o seu pagamento",
  see_you: "Até breve,",
  ancv_signature: "A equipa da Agência Nacional para a Chèque-Vacances",
  ancv_amount: "Quantia recebida",
  total_amount: "Montante solicitado",
  ancv_transaction_id: "Transaction reference",
  remaining_amount: "Montante restante devido",
  excluding_fees: "excluindo taxas de financiamento",
  installment_cta:
    "Pagar o montante restante devido em {installments_nb}x por cartão de crédito",
  deferred_cta: "Pagar o saldo mais tarde por cartão de crédito",
  infos_ancv_and_bnpl:
    "Uma vez que o montante a ser pago na Chèque-Vacances tenha sido validado utilizando a aplicação Chèque-Vacances, ser-lhe-á oferecido um calendário de pagamento com base no montante restante a ser pago por cartão de crédito.",
  warning_delay_bnpl:
    "Tem {delayInMinutes} minutos para finalizar o seu pagamento. Após este tempo, o pagamento na Chèque-Vacances e a sua reserva serão cancelados.",
  /** ----------------------------------------------------
   *                        CHECKOUT
   *  ---------------------------------------------------- */
  input_bank_details: "Introduzo os meus dados bancários",
  amount_to_pay: "Montante a ser pago",
  total_purchase_amount: "Montante total",
  split_explanation: "Se todos os participantes pagarem dentro de 48 horas, eu pago",
  i_pay_my_share: "Eu pago a minha parte",
  i_pay_my_installment: "Pago a minha mensalidade",
  anticipated_payment_warning:
    "Está a preparar-se para pagar que vence em <b>{date}</b>, ou seja, <b>{days} dias</b> antes da data inicialmente acordada",
  i_change_my_payment_method: "Atualizar minha forma de pagamento",
  payment_method_fingerprint_amount:
    "Uma impressão do seu cartão bancário no valor de {amount} pode aparecer na sua conta. Isso é temporário e nenhum valor será deduzido de você.",
  card_number: "Número do cartão",
  expiration_date: "Data de expiração",
  security_code: "Código de segurança",
  card_network: "Sieć kart",
  notice_payment: "instruções de utilização do serviço de pagamento",
  accept_cta_installment:
    "Ao clicar em Validar, aceito {0}. Por fim, certifico que o cartão utilizado para pagar a minha compra está associado a uma conta aberta em meu nome.",
  split_default_message:
    "Receberei um pedido de autorização para o montante total. Nenhum dinheiro será debitado. Os meus amigos têm 48 horas para pagar. Depois deste tempo, se um deles não pagar, serei debitado da sua parte.",
  secured_payment: "Pagamento seguro",
  deadline_on: "previsto para",
  with_fees_amount: "(dos quais {amount} dos custos)",
  basket_amount: "Montante do carrinho :",
  ill_pay_later: "Pagarei em {date}",
  ill_pay_later_on_delivery: "Pagarei na entrega (e até {data})",
  penalties_detail:
    "dos quais {penalties_percentage}% de penalizações tardias: {amount}",
  not_supported_cards:
    "Não são aceites cartões pré-pagos, cartões de autorização sistemática tais como Electron, Maestro, Compte Nickel, Revolut, e cartões virtuais ou ecards.",
  payment_method_issue:
    "Ocorreu um erro ao utilizar o seu método de pagamento. Contacte a equipa de apoio do comerciante.",
  order_info_multiple_shipment:
    "A encomenda pode consistir em várias remessas. Cada embarque terá o seu próprio calendário, que terá início no momento do embarque.",
  deposit_deadline_on: "Depósito a",
  balance_deadline_on: "Balanço a",
  balance_to_pay_later: "Pagarei o saldo em {date}",
  test_another_card: "Experimente outro cartão.",
  entry_error: "Erro de entrada.",
  card_declined: "O seu cartão foi recusado.",
  invalid_iban: "O Iban entrado está incorrecto.",
  stripe_library_not_loaded: "A biblioteca da Stripe V3 não foi carregada",
  taeg: "O cliente recebe uma facilidade de pagamento de {num_of_days} dias, que incorrerá numa taxa de {amount} ou seja, uma TAEG de {taeg}%.",
  amount_paid_in_ancv: "Montante pago em Chèque-Vacances",
  advertisement_optin: "Desejo receber comunicações comerciais de {optin_advertiser}",
  electronic_optin:
    "Desejo receber comunicações comerciais electrónicas da CA Consumer Finance",
  phone_mail_optout:
    "Não pretendo receber comunicações comerciais da CA Consumer Finance por telefone ou correio",
  contract_message_start: "Aceito as i",
  contract_message_link_installment:
    "informações pré-contratuais e contratuais relativas ao pagamento em prestações ",
  contract_message_link_deferred:
    "informações pré-contratuais e contratuais sobre o pagamento diferido ",
  contract_message_end:
    "e certifico que o cartão utilizado para pagar a minha compra está ligado a uma conta aberta em meu nome.",
  /** ----------------------------------------------------
   *                        COMMONS
   *  ---------------------------------------------------- */
  add: "Adicionar",
  me: "Eu",
  friend: "amigo",
  submit: "Validar",
  yes: "Sim",
  no: "Não",
  update: "Editar",
  apply: "Aplicar",
  see_faq: "Ver as nossas FAQ para mais informações",
  language: "Língua:",
  back_to_website: "Voltar ao {website}",
  unknown: "Desconhecido",
  on_date: "Em {date}",
  footer_question_to_merchant:
    "Para qualquer dúvida relacionada à sua compra, entre em contato diretamente com {merchantName}.",
  footer_question_to_pledg:
    'Para qualquer informação relacionada ao seu financiamento, consulte nossas <a href="{faqUrl}" target="_blank">FAQ</a> ou escreva-nos para <a href="mailto:{contactEmail}"> {contactEmail} </a>.',
  notice_access_link: "Aviso de utilização do serviço de pagamento",
  data_policy_link:
    "<a href='{dataPolicyUrl}' target='_blank'>Política de privacidade de dados</a>",
  data_policy:
    "Os meus dados serão utilizados num processo totalmente automatizado para conceder uma facilidade de pagamento: <a href='{dataPolicyUrl}' target='_blank'>política de privacidade de dados</a>.",
  contact_access_link:
    "<a href='{contactUrl}' target='_blank'>Entre em contato com o serviço de pagamento</a>",
  no_information: "Nenhuma informação fornecida",
  no_psp_source: "Nenhum método de pagamento disponível",
  redirected_from_message:
    "{merchantName} redirecionou-o para {operatorTeamName} para concluir o seu pagamento.",
  your_purchase: "A tua compra",
  nationality_short: "Nacionalidade",
  birth_last_name_short: "Nome de nascimento",
  email_address: "Endereço de correio eletrónico",
  full_address: "Endereço para correspondência",
  birthdate: "Data de nascimento",
  birthplace: "Lugar de nascimento",
  firstname: "Primeiro nome",
  lastname: "Nome",
  phone_number_short: "Número de telefone",
  /** ----------------------------------------------------
   *                    DASHBOARD BUYER
   *  ---------------------------------------------------- */
  notice_infos: "Favor consultar o aviso do serviço de pagamento",
  error_invalid_contact: "O contato é inválido",
  fees_amount: "(Taxas: {amount} {currency})",
  here: "aqui",
  deferred_intro:
    "Status de sua compra adiada de {date} a {hour} (hora de {timezone_city}) em {merchantName}.",
  installment_intro:
    "Status da sua compra dividida de {date} a {hour} (hora de {timezone_city}) em {merchantName}.",
  split_intro:
    "Status de sua compra compartilhada de {date} a {hour} (horário de {timezone_city}) em {merchantName}.",
  penalties_amount: "(Penalties: {amount} {currency})",
  proceed_payment_late: "Regularizar minha situação: {amount} {currency}",
  proceed_payment_in_advance: "Pagar minha próxima parcela: {amount} {currency}",
  dashboard_buyer_your_basket: "Sua cesta",
  dashboard_buyer_your_fees: "Taxa financeira ({feesPercentage}%)",
  dashboard_buyer_payment_schedule: "As suas datas de vencimento",
  dashboard_buyer_products: "Os seus produtos",
  dashboard_buyer_see_schedule: "Ver o horário",
  dashboard_buyer_overdue: "Falta de pagamento",
  dashboard_buyer_overdue_payment: "Regularização da sua situação",
  dashboard_buyer_overdue_payment_text:
    "O pagamento para a data de vencimento de {date} falhou. Por favor, proceda à regularização da sua situação.",
  dashboard_buyer_your_payment_method: "Sua forma de pagamento",
  dashboard_buyer_your_payment_method_expired_soon:
    "Sua forma de pagamento está prestes a expirar. Atualize-o para evitar quaisquer incidentes de pagamento.",
  dashboard_buyer_your_payment_method_text_installment:
    "Os débitos das suas parcelas mensais são efetuados nas datas acordadas através da seguinte forma de pagamento:",
  dashboard_buyer_your_payment_method_text_deferred:
    "O valor devido será levantado na data acordada, através da seguinte forma de pagamento:",
  dashboard_buyer_your_payment_method_button: "Alterar minha forma de pagamento",
  dashboard_buyer_share_captured_amount: "Pago:",
  dashboard_buyer_share_not_charged_amount: "Cancelado:",
  dashboard_buyer_share_refunded_amount: "Devolveu:",
  dashboard_buyer_share_outstanding_amount: "Montante pendente:",
  dashboard_buyer_title_installment:
    'Seu <span class="text-secondary">pagamento em {installmentsNb} vezes</span> em {merchantName}',
  dashboard_buyer_title_deferred:
    'Seu <span class="text-secondary">pagamento diferido</span> em {merchantName}',
  dashboard_buyer_title_down_payment:
    'Seu <span class="text-secondary">pagamento com depósito</span> em {merchantName}',
  dashboard_buyer_title_split:
    'Seu <span class="text-secondary">pagamento compartilhado</span> em {merchantName}',
  dashboard_buyer_status_total_refund:
    "Sua compra foi reembolsada de acordo com os termos e condições de {merchantName} e do serviço de pagamento.",
  dashboard_buyer_status_split_waiting_payment:
    "Nem todos os participantes pagaram sua parte.",
  dashboard_buyer_status_fully_paid:
    "Sua compra foi totalmente paga. Vejo você em breve em {merchantName}.",
  dashboard_buyer_status_overdue:
    "Falha no pagamento de uma parcela. Regularize sua situação ou entre em contato com o serviço de pagamento o mais rápido possível.",
  dashboard_buyer_status_ongoing:
    "O pagamento da sua compra ocorre normalmente, não sendo necessária nenhuma ação de sua parte.",
  dashboard_buyer_status_psp_source_expired:
    "Sua forma de pagamento está expirando. Atualize-o para continuar pagando suas parcelas.",
  resend_message: "Reenviar mensagem",
  split_leader_label: "Você",
  state_leader_not_paid: "Pendente",
  state_expired_not_paid: "Falha no pagamento, a regularizar",
  state_future_not_paid: "Débito automático na data de vencimento",
  state_preauthorization_ok: "Impressão bancária",
  state_sepa_debit_pending: "Débito SEPA com validação pendente",
  state_sofort_pending: "Pagamento Sofort pendente",
  state_ancv_pending_transfer: "Aguardando validação",
  state_ancv_processing: "Pendente de pagamento por Chèques-Vacances",
  state_ancv_preauthorization_ok: "Pago por Chèques-Vacances",
  state_payment_ok: "Pago",
  state_paid_by_leader: "Pago por você",
  state_unknown: "Aguardando pagamento",
  state_fully_refunded: "Devolveu",
  state_fully_not_charged: "Cancelado",
  split_explanation_1:
    "Cada participante tem até {date} às {hour} (hora de {timezone_city}) para pagar sua parcela. Após esse horário, será cobrado o valor de sua parcela ({amount} {currency}) conforme bem como ações não pagas.",
  dashboard_order_link: "Voltar ao painel de controlo das encomendas",
  header_amount: "Quantidade",
  header_participants: "Participantes",
  header_status: "Status",
  error_dashboard_title: "Painel indisponível",
  error_dashboard_subtitle: "O resumo da sua compra não pôde ser exibido.",
  error_dashboard_reason_title: "Por qual motivo?",
  error_dashboard_reason_1: "A compra em questão pode não estar disponível",
  error_dashboard_reason_1_details:
    "Este painel não está disponível para compras sendo criadas ou canceladas.",
  error_dashboard_reason_2: "Esta página pode ter expirado",
  error_dashboard_reason_2_details:
    "Por razões de segurança, esta página está sujeita a expiração. Por favor, feche a página e clique no link original novamente.",
  /** ----------------------------------------------------
   *                        ERRORS
   *  ---------------------------------------------------- */
  purchaseNotFound: "Nenhuma compra associada a este URL.",
  genericError:
    "Ocorreu um erro. Se o erro ocorrer novamente, por favor contacte-nos em contact{'@'}pledg.co.",
  error_infra_unavailable:
    "O serviço está atualmente indisponível. Por favor, recarregue a página ou tente novamente mais tarde.",
  notAddedValidDestinationWarning:
    "Por favor clique no botão '+ Adicionar' para adicionar o seu amigo.",
  invalidDestination: "Este número de e-mail/móvel não é válido !",
  alreadyUsedDestination: "Este número de e-mail/móvel já é utilizado !",
  invalidAmount: "Este montante não é válido !",
  closingWarning: "Quer mesmo fechar esta janela ?",
  singleShareWarning: "Quer realmente pagá-lo você mesmo ?",
  Confirmation3DSTimeoutError: "Falha na autenticação. Por favor, tente novamente.",
  ancvConfirmationTimeoutError:
    "Erro ao pagar com Chèques-Vacances. Por favor, tente novamente.",
  requiredPropertyMissingOrInvalid:
    "O parâmetro obrigatório {property} está em falta ou é inválido",
  requiredPropertyMissing: "Falta o parâmetro obrigatório {property}.",
  propertyInvalid: "O parâmetro {property} é inválido",
  paymentRefused: "O pagamento foi recusado",
  errorHappened:
    "<strong>Pedido de facilidade de pagamento recusado, por favor utilizar outro método de pagamento.</strong>",
  errorAmountHold:
    "Dependendo do seu banco, a sua conta pode ser debitada. Este débito é temporário e não é debitado qualquer montante da sua conta.",
  ineligibilityPaymentMethod: "Por favor, utilize outro método de pagamento",
  ineligibilityPaymentMethodRedirect:
    "Será redireccionado para outro método de pagamento",
  ineligibilityPaymentMethodRedirectWithMerchant:
    "Será redireccionado para o sítio Web {merchantName} a fim de escolher outro método de pagamento",
  ineligibilityErrorContentWithMerchant:
    "Com base nas informações fornecidas por {merchantName} ou recolhidas diretamente pelo prestador de serviços de pagamento e na sequência de um processo de decisão totalmente automatizado, o seu pedido não preenche os critérios de elegibilidade para esta facilidade de pagamento. Esta decisão não prejudica o resultado de quaisquer futuros pedidos de financiamento e pode contactar-nos escrevendo para {infoEmail} para obter mais informações.",
  ineligibilityErrorContent:
    "Com base nas informações recolhidas diretamente pelo prestador de serviços de pagamento e na sequência de um processo de decisão totalmente automatizado, o seu pedido não preenche os critérios de elegibilidade para esta facilidade de pagamento. Esta decisão não prejudica o resultado de quaisquer futuros pedidos de financiamento e pode contactar-nos escrevendo para {infoEmail} para obter mais informações.",
  retry_error: "Pedido de facilidade de pagamento recusado.",
  provideMerchantUid: "Por favor forneça o merchantUid",
  invalidPhoneNumber: "Este número de telefone não é válido.",
  IdentificationConfirmationTimeoutError:
    "Ocorreu um erro durante a verificação da identidade. Se o erro ocorrer novamente, por favor contacte-nos em {contact_email}.",
  splitIncorrectAmounts: "Os montantes introduzidos parecem estar incorrectos",
  fipenDownloadError:
    "Ocorreu um erro ao recuperar as suas informações pré-contratuais. Por favor, tente novamente mais tarde.",
  contractDownloadError:
    "Ocorreu um erro ao recuperar as suas informações contratuais. Por favor, tente novamente mais tarde.",
  contractSignError:
    "Ocorreu um erro ao assinar o seu contrato. Por favor, tente novamente mais tarde",
  /** ----------------------------------------------------
   *                    STEPS TITLE
   *  ---------------------------------------------------- */
  title_payment_missing_info:
    "Eu completo minhas <span>informações de faturamento</span>",
  title_payment_solutions: "Eu escolho meu <span>plano de financiamento</span>",
  title_missing_info_installment:
    "Pago minha compra <span>em {installmentsNb} vezes</span>",
  title_missing_info_deferred: "Pago minha compra <span>posteriormente</span>",
  title_missing_info_down_payment: "Pago minha compra <span>com entrada</span>",
  title_missing_info_split: "Pago minha compra <span>dividida</span>",
  title_otp: "Confirmo meu <span>telefone</span>",
  title_revenue_collection: "Declaro meus rendimentos e <span>despesas</span>",
  title_confirm_info: "Confirmo as minhas <span>informações</span>",
  title_payment: "Insiro meu <span>método de pagamento</span>",
  title_processing:
    "Permito que a {operatorTeamName} verifique minha <span>solvência</span>",
  /** ----------------------------------------------------
   *                        IDENTIFICATION
   *  ---------------------------------------------------- */
  identity_check: "Verificação da identidade",
  identification_success: "A verificação é validada",
  identification_failure: "A auditoria falhou",
  identification_processing: "A auditoria está em curso",
  identity_check_warning_message:
    "Está prestes a ser redireccionado para verificar a sua identidade.",
  /** ----------------------------------------------------
   *                    INFORMATION MISSING
   *  ---------------------------------------------------- */
  address_street: "Endereço (Número, Rua, etc...)",
  address_zipcode: "Código postal (ex.: 1000-001)",
  birth_address_zipcode: "Código postal de nascimento (ex.: 1000-001)",
  civility: "Civismo (como consta no seu documento de identidade)",
  birth_last_name: "Nome de nascimento (ex.: Silva)",
  madam: "Senhora",
  mister: "Senhor",
  address_city: "Cidade (ex.: Lisboa)",
  address_province: "Região",
  address_country: "País (ex.: Portugal)",
  birth_date_placeholder: "Data de nascimento (dd/mm/aaaa)",
  birth_city: "Cidade de nascimento",
  birth_country: "País de nascimento",
  search_birthplace: "Local de nascimento (ex.: 1000-001 Lisboa, Portugal)",
  birth_place_autocomplete_error: "Por favor, seleccione um lugar de nascimento válido",
  nationality: "Nacionalidade",
  phone_number: "Número de telefone (ex.: +351912345678)",
  missing_information_title: "Precisamos de alguma informação adicional",
  missing_information_subtitle:
    "Para dar seguimento ao seu pedido de financiamento, necessitamos de mais informações. ",
  missing_information_subtitle_payer: "Introduza a sua informação de facturação",
  email: "Correio electrónico",
  day: "Dia",
  month: "Mês",
  year: "Ano",
  fipen_text: "Pode agora descarregar ",
  fipen_link: "as suas informações pré-contratuais.",
  /** ----------------------------------------------------
   *                    REVENUE
   *  ---------------------------------------------------- */
  title_revenue: "Precisamos de mais alguma informação",
  revenue_explanation:
    "Para conhecermos a sua capacidade de endividamento, precisamos de informações sobre os seus rendimentos e as suas despesas mensais.",
  declared_revenue: "Receitas depois de impostos",
  declared_expenditure: "Total de encargos",
  declared_csp: "Categoria profissional",
  tooltip_revenue: "Salário líquido, apoio financeiro, rendimentos imobiliários...",
  tooltip_expenditure: "Créditos correntes, renda, pensão alimentícia",
  back_to_merchant: "Eu compreendo, leva-me de volta ao comerciante",
  professional_categories: [
    "Gestores executivos",
    "Profissões intelectuais e científicas",
    "Profissões remuneradas intermédias",
    "Pequenos empresários (não-empregados)",
    "Trabalhadores qualificados",
    "Trabalhadores assalariados qualificados",
    "Profissões assalariadas pouco qualificadas",
    "Reformados",
    "Outras pessoas desempregadas",
  ],
  /** ----------------------------------------------------
   *                    SUMMARY
   *  ---------------------------------------------------- */
  title_summary: "Resumo do seu dossier",
  summary_explanation: "Antes de assinar o seu contrato, confirme os seus dados.",
  summary_unchangeable: "Não modificável",
  summary_your_financing: "O seu financiamento",
  summary_your_identity: "A sua identidade",
  summary_your_revenue: "As suas finanças",
  summary_your_contact_details: "Os seus dados de contacto",
  summary_purchase_amount: "Montante da compra",
  summary_deposit_amount: "Montante do depósito",
  summary_financed_amount: "Montante financiado",
  summary_total_amount_due: "Montante total devido",
  summary_monthly_installments: "{installments_nb} prestações mensais de",
  summary_fixed_borrowing_rate: "Taxa fixa de empréstimo",
  summary_net_monthly_income: "Rendimento mensal líquido",
  summary_recurring_expenses: "Despesas recorrentes",
  summary_csp: "Categoria profissional",
  summary_acknowledgement_statement:
    "Confirmo que tomei conhecimento da ficha de informação pré-contratual europeia normalizada. Certifico por minha honra que as informações acima referidas são corretas, declaro que não tenho em curso um plano de sobre-endividamento e declaro que o empréstimo solicitado não tem por objetivo a consolidação de créditos",
  summary_terms_and_conditions:
    "Confirmo que li e aceitei as condições gerais dos serviços digitais",
  /** ----------------------------------------------------
   *                    INFORMATION SPLIT
   *  ---------------------------------------------------- */
  title_whodoisharewith: "Com que amigos partilho a minha encomenda?",
  subtitle_addfriends: "Acrescento o e-mail ou números de telemóvel dos meus amigos",
  placeholder_friend_email_phone_number: "Email ou número de telemóvel do meu amigo",
  attendees: "Sem participantes | 1 participante | {count} participantes",
  pay_alone: "Quero pagar por mim mesmo",
  /** ----------------------------------------------------
   *                        PAYMENT SOLUTIONS
   *  ---------------------------------------------------- */
  payment_solutions_title: "Minhas facilidades de pagamento",
  payment_solutions_subtitle: "na {company}",
  payment_solutions_choice_infos:
    "Selecione a facilidade de pagamento que mais lhe convém",
  payment_schedule_installment_homogeneous: "{installments_nb}x {amount}",
  payment_schedule_installment_disparate:
    "{amount} então {installments_nb}x {second_amount}",
  payment_schedule_deferred_delay_in_days: "{amount} em {delay_in_days} dias",
  payment_schedule_deferred_day_of_next_month: "{amount} dia {payment_date}",
  payment_solutions_no_merchants_found:
    "Nenhuma solução de pagamento preenche os critérios de elegibilidade com base nas informações fornecidas",
  /** ----------------------------------------------------
   *                        PROCESSING
   *  ---------------------------------------------------- */
  payment_pending: "Pagamento em curso",
  payment_pending_information_1:
    "Por favor, não actualize a página enquanto estiver a processar a informação.",
  payment_pending_information_2: "Este passo pode demorar vários minutos.",
  payment_cancelled: "Pagamento cancelado",
  payment_analysis_needed: "Informação adicional necessária",
  payment_analysis_information_1:
    "Como parte do seu pedido de financiamento, gostaríamos de recolher um histórico de 90 dias das suas transacções junto do seu banco",
  payment_analysis_information_2:
    "Este serviço é gerido pelo nosso parceiro {providerLabel}, líder na partilha segura de dados bancários, aprovado pela ACPR",
  payment_analysis_information_3:
    "Ao clicar em Validar, aceito transmitir à {operatorTeamName} as informações extraídas dos meus extractos bancários",
  payment_analysis_information_4:
    "Se não o desejar, pode voltar à seleção dos modos de pagamento para {merchantName} clicando na cruz",
  payment_analysis_in_progress: "Análise da informação actual",
  payment_analysis_progress_information_1:
    "O seu pedido de uma facilidade de pagamento está a ser analisado.",
  payment_analysis_progress_information_2:
    "Por favor, não actualize a página enquanto a informação estiver a ser processada. Este passo pode demorar vários minutos.",
  /** ----------------------------------------------------
   *                        SUCCESS
   *  ---------------------------------------------------- */
  congratulations: "Parabéns!",
  payment_registered: "O seu pagamento foi registado.",
  payment_method_registered: "Seu novo método de pagamento foi levado em consideração.",
  /** ----------------------------------------------------
   *                        TWO-FA
   *  ---------------------------------------------------- */
  phone_number_check: "Verificando seu número de telefone",
  phone_number_tooltip:
    "Il s'agit du numéro de téléphone que vous avez donné au commerçant.",
  wrong_code: "Este código parece estar incorrecto, por favor tente novamente",
  unknown_error: "Ocorreu um erro",
  code_valid: "Código válido",
  please_type_code: "Escreva este código no campo abaixo",
  you_ll_receive_text: "Acabámos de lhe enviar um código para ",
  did_not_receive: "Não recebi um código",
  send_again: "recomeçar",
  sending_sms: "Transmissão de código em andamento",
  verify: "Verificar",
  code_transmission_error:
    "Ocorreu um erro ao transmitir o código. Por favor, tente novamente",
  /** ----------------------------------------------------
   *                        VALIDATIONS
   *  ---------------------------------------------------- */
  validations: {
    required: "O campo é obrigatório",
    phone_number: "O número de telefone é inválido",
    date_format: "O formato de data não é válido ({_value_})",
    invalid_birthdate: "Introduza uma data de nascimento válida",
    date_between: "A data não é inválida",
    min: "O campo deve conter pelo menos {length} caracteres.",
    positive: "O valor deve ser um número positivo.",
    email: "O formato do correio electrónico é inválido ({_value_})",
  },
  /** ----------------------------------------------------
   *                        CUSTOM SCREEN
   *  ---------------------------------------------------- */
  custom_success_screen: {
    title: 'A sua reserva não está concluída até clicar em "Comprar agora"',
    subtitle:
      "Quando a sua reserva estiver concluída, a sua viagem será paga na totalidade e estará pronto para viajar",
    text: "Poderá aparecer uma pré-autorização na sua conta bancária. Notificá-lo-emos antes da data de vencimento de cada pagamento. A falta de pagamento pode resultar no cancelamento da viagem.",
  },
  /** ----------------------------------------------------
   *                        ERROR SCREEN
   *  ---------------------------------------------------- */
  error_screen: {
    error_410: "O recurso pedido já não está disponível",
    error_404: "Erro 404",
    expired: "O URL solicitado expirou",
    not_found: "A página que está a procurar não foi encontrada",
  },
};
