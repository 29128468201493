<script>
import { useFunnelStep } from "@/composables/funnelStep";
import getFormattedPrice from "@/helpers/getFormattedPrice";
import { dateFnsLocale } from "@/translations/locales";
import { parseAndFormatDate } from "@/helpers/date";
import COUNTRIES from "@/helpers/countryCodes";
import SofincoLogo from "@/components/SofincoLogo.vue";
import ScreenTitle from "@/components/ScreenTitle.vue";
import { SHARE, PURCHASE, ACCOUNT } from "@/store/namespaces";
import { Lock } from "@element-plus/icons-vue";
import { mapActions, mapGetters } from "vuex";
import PurchaseService from "@/api/purchaseService";

export default {
  name: "FunnelSummary",
  components: {
    SofincoLogo,
    ScreenTitle,
    Lock,
  },
  setup(_, ctx) {
    const { funnelStepDone } = useFunnelStep(ctx);
    return { funnelStepDone };
  },
  data() {
    return {
      loading: false,
      errorMessage: undefined,
      acknowledgementStatement: false,
      termsAndConditions: false,
    };
  },
  computed: {
    ...mapGetters(["rawPurchaseUid", "rawShareUid"]),
    ...mapGetters(PURCHASE, ["purchase", "fullAddressAvailable"]),
    ...mapGetters(SHARE, ["share", "allShares"]),
    ...mapGetters(ACCOUNT, [
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "nationality",
      "birthLastName",
      "birthDate",
      "birthCity",
      "birthZipcode",
    ]),
    summary() {
      const sections = [
        {
          title: this.$t("your_purchase"),
          data: [
            {
              label: this.$t("summary_purchase_amount"),
              data_cy: "purchase-amount",
              value: this.formatPrice(this.purchase.amount_cents),
            },
            {
              label: this.$t("summary_deposit_amount"),
              data_cy: "deposit-amount",
              value: this.formatPrice(this.share.amount_cents),
            },
            {
              label: this.$t("summary_financed_amount"),
              data_cy: "financed-amount",
              value: this.formatPrice(this.purchase.financed_amount_cents),
            },
          ],
        },
        {
          title: this.$t("summary_your_financing"),
          data: [
            {
              label: this.$t("summary_total_amount_due"),
              data_cy: "total-amount-due",
              value: this.formatPrice(
                this.purchase.financed_total_outstanding_amount_cents
              ),
            },
            {
              label: this.$t("summary_monthly_installments", {
                installments_nb: this.purchase.shares.length - 1,
              }),
              data_cy: "derived-share-amount",
              value: this.formatPrice(this.derived_share_amount_cents),
            },
            {
              label: this.$t("summary_fixed_borrowing_rate"),
              data_cy: "fixed-borrowing-rate",
              value: `${this.formatPrice(this.purchase.fees_amount_cents)} (${this.purchase.buyer_fees_percentage}%)`,
            },
          ],
        },
        {
          title: this.$t("summary_your_identity"),
          data: [
            {
              label: this.$t("firstname"),
              data_cy: "firstname",
              value: this.firstName || "-",
            },
            {
              label: this.$t("lastname"),
              data_cy: "lastname",
              value: this.lastName || "-",
            },
            {
              label: this.$t("nationality_short"),
              data_cy: "nationality",
              value: this.country || "-",
            },
            {
              label: this.$t("birth_last_name_short"),
              data_cy: "birth-last-name",
              value: this.birthLastName || "-",
            },
            {
              label: this.$t("birthdate"),
              data_cy: "birth-date",
              value: this.birthDate
                ? parseAndFormatDate(this.birthDate, {
                    formatDateFormat: "P",
                    formatLocale: dateFnsLocale(this.$i18n.locale),
                  })
                : "-",
            },
            {
              label: this.$t("birthplace"),
              data_cy: "birth-place",
              value:
                this.birthCity && this.birthZipcode
                  ? `${this.birthCity} (${this.birthZipcode})`
                  : this.birthCity || this.birthZipcode || "-",
            },
          ],
        },
      ];
      if (this.purchase.collected_revenue) {
        sections.push({
          title: this.$t("summary_your_revenue"),
          data: [
            {
              label: this.$t("summary_net_monthly_income"),
              data_cy: "net-monthly-income",
              value: this.formatPrice(
                this.purchase.collected_revenue.declared_revenue * 100
              ),
            },
            {
              label: this.$t("summary_recurring_expenses"),
              data_cy: "recurring-expenses",
              value: this.formatPrice(
                this.purchase.collected_revenue.declared_expenditure * 100
              ),
            },
            {
              label: this.$t("summary_csp"),
              data_cy: "csp",
              value: this.purchase.collected_revenue.declared_csp,
            },
          ],
        });
      }
      sections.push({
        title: this.$t("summary_your_contact_details"),
        data: [
          {
            label: this.$t("full_address"),
            data_cy: "address",
            value: this.fullAddressAvailable
              ? `${this.purchase.address_street}\n${this.purchase.address_city} (${this.purchase.address_zipcode})`
              : "-",
          },
          {
            label: this.$t("email_address"),
            data_cy: "email",
            value: this.email || "-",
          },
          {
            label: this.$t("phone_number_short"),
            data_cy: "phone-number",
            value: this.phoneNumber || "-",
          },
        ],
      });
      return sections;
    },
    derived_share_amount_cents() {
      return this.allShares[this.purchase.shares[1]].amount_cents;
    },
    country() {
      const allCountries = COUNTRIES[this.$i18n.locale];
      const country = allCountries.find((c) => c.code === this.nationality);
      return country ? country.label : null;
    },
  },
  async mounted() {
    await this.fetchPurchaseAction({ purchaseUid: this.rawPurchaseUid });
    await this.fetchShareAction({ shareUid: this.rawShareUid });
    console.log(this.allShares);
    if (!this.purchase.is_pledg_long_cb || this.purchase.is_summary_validated) {
      this.funnelStepDone();
    }
  },
  methods: {
    ...mapActions(PURCHASE, ["fetchPurchaseAction"]),
    ...mapActions(SHARE, ["fetchShareAction"]),
    formatPrice(amount) {
      return getFormattedPrice(amount, this.purchase.currency, this.$i18n.locale);
    },
    async handleSubmitClick() {
      this.errorMessage = undefined;
      if (this.acknowledgementStatement && this.termsAndConditions) {
        this.loading = true;
        try {
          await PurchaseService.summaryValidation(this.rawPurchaseUid);
          this.funnelStepDone();
        } catch (error) {
          this.errorMessage = error?.errorHuman || this.$t("genericError");
          this.loading = false;
        }
      }
    },
  },
};
</script>

<template lang="pug">
ScreenTitle(:title="$t('title_confirm_info')")

.screen
    .screen-section
      h1 {{ $t("title_summary") }}
      p.mt-5.text-center {{ $t("summary_explanation") }}

    .screen-section
      .summary-section(v-for='section in summary' :key='section.title')
        .columns-2.mb-2
          .summary-section-title {{ section.title }}
          .text-right.summary-data-label
            span {{ $t("summary_unchangeable") }}
            el-icon.el-icon--right(style="top: 2px")
              lock
        .grid.grid-cols-3.gap-2
          .mt-4(v-for='item in section.data' :key='item.label')
            .summary-data-label.truncate.mb-2(:title="item.label") {{ item.label }}
            .summary-data-value.whitespace-pre-wrap(:data-cy="item.data_cy") {{ item.value }}

    .screen-section.submit-section
      VVForm()
        .line_disclaimer
          label.flex.items-top.cursor-pointer.justify-start.mb-2
            el-checkbox.h-min.pr-2(
              v-model="acknowledgementStatement"
              data-cy="acknowledgement-statement"
            )
            span.text-justify {{ $t('summary_acknowledgement_statement') }}
          label.flex.items-top.cursor-pointer.justify-start.mb-2
            el-checkbox.h-min.pr-2(
              v-model="termsAndConditions"
              data-cy="terms-and-conditions"
            )
            span.text-justify {{ $t('summary_terms_and_conditions') }}
        .screen-section.section-error(v-if='errorMessage') {{ errorMessage }}
        ElButton(
          class="w-1/2"
          type="primary"
          :disabled='!acknowledgementStatement || !termsAndConditions'
          :loading='loading'
          @click='handleSubmitClick'
          data-cy="submit"
        )
          .grow {{ $t("submit") }}

    SofincoLogo
</template>

<style scoped lang="scss">
.summary-section {
  width: 570px;
  padding: 1.25rem;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
}
.summary-section:not(:last-child) {
  margin-bottom: 1rem;
}
.summary-section-title {
  font-size: 1.25rem;
  font-weight: bold;
}
.summary-data-label {
  color: #b5b5b5;
  font-size: 15px;
  line-height: 1.25rem;
}
.summary-data-value {
  font-weight: bold;
}
.screen-section.submit-section {
  padding-top: 0;
}
</style>
