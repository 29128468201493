export const FUNNEL_ROUTE_NAMES = {
  FUNNEL_ENTRYPOINT: "FunnelEntrypoint",
  FUNNEL_INFORMATION_MISSING_PRE_CREATION: "FunnelInformationMissingPreCreation",
  FUNNEL_INFORMATION_SPLIT: "FunnelInformationSplit",
  FUNNEL_2FA: "Funnel2FA",
  FUNNEL_ORDER_2FA: "FunnelOrder2FA",
  FUNNEL_INFORMATION_MISSING_POST_CREATION: "FunnelInformationMissingPostCreation",
  FUNNEL_IDENTIFICATION: "FunnelIdentification",
  FUNNEL_REVENUE: "FunnelRevenue",
  FUNNEL_CHECKOUT: "FunnelCheckout",
  FUNNEL_PAYMENT_METHOD_UPDATE: "FunnelPaymentMethodUpdate",
  FUNNEL_PROCESSING: "FunnelProcessing",
  FUNNEL_PROCESSING_POST_SIGNATURE: "FunnelProcessingPostSignature",
  FUNNEL_ORDER_CHECKOUT: "FunnelOrderCheckout",
  FUNNEL_ORDER_PROCESSING: "FunnelOrderProcessing",
  FUNNEL_ORDER_ENTRYPOINT: "FunnelOrderEntrypoint",
  FUNNEL_SUMMARY: "FunnelSummary",
  FUNNEL_SUCCESS: "FunnelSuccess",
  FUNNEL_ANCV: "FunnelAncv",
};

export const DASHBOARD_BUYER_ROUTE_NAMES = {
  DASHBOARD_BUYER_PURCHASE_OVERVIEW: "DashboardBuyerPurchaseOverview",
  DASHBOARD_BUYER_ORDER_OVERVIEW: "DashboardBuyerOrderOverview",
};

export const NO_RELOAD_ROUTES = [FUNNEL_ROUTE_NAMES.FUNNEL_SUCCESS];

export const PAYMENT_SOLUTIONS_ROUTE_NAMES = {
  PAYMENT_SOLUTIONS: "PaymentSolutions",
  PAYMENT_SOLUTIONS_PRECHECK: "PaymentSolutionsPreCheck",
  PAYMENT_SOLUTIONS_MISSING_INFORMATION: "PaymentSolutionsMissingInformation",
  PAYMENT_SOLUTIONS_LIST: "PaymentSolutionsList",
};

export const ROUTE_NAMES = {
  ...FUNNEL_ROUTE_NAMES,
  ...DASHBOARD_BUYER_ROUTE_NAMES,
  ...PAYMENT_SOLUTIONS_ROUTE_NAMES,
  ACCESS_ERROR: "ACCESS_ERROR",
};

const ORDER_UID = "orderUid";
const PURCHASE_UID = "purchaseUid";
const SHARE_UID = "shareUid";
const PAYMENT_FUNNEL_UID = "paymentFunnelUid";
const COMPANY_UID = "companyUid";
const ACCESS_ERROR_TYPE = "accessErrorType";

export const ROUTE_PARAMS = {
  PURCHASE_UID,
  SHARE_UID,
  PAYMENT_FUNNEL_UID,
  ORDER_UID,
  ACCESS_ERROR_TYPE,
};

export const ROUTE_QUERY_PARAMS = {
  COMPANY_UID,
};
/**
  FUNNEL STEPS

  Every funnel is a list of steps, where a step is linked to the router
  The Funnel is selected automatically using selectFunnelSteps() method
  according to current merchant payment type.

  *routeName* refers to a route name in router.js
  *routeParams* is a list of mandatory parameters in url
*/
const stepFunnelEntrypoint = {
  routeName: ROUTE_NAMES.FUNNEL_ENTRYPOINT,
  routeParams: [],
  routeOptions: {},
};
const stepFunnelInformationMissing = {
  routeName: ROUTE_NAMES.FUNNEL_INFORMATION_MISSING_PRE_CREATION,
  routeParams: [],
  routeOptions: {},
};
const stepFunnelInformationSplit = {
  routeName: ROUTE_NAMES.FUNNEL_INFORMATION_SPLIT,
  routeParams: [PURCHASE_UID],
  routeOptions: {},
};
const stepFunnelRevenue = {
  routeName: ROUTE_NAMES.FUNNEL_REVENUE,
  routeParams: [PURCHASE_UID, SHARE_UID],
  routeOptions: {},
};
const stepFunnel2FA = {
  routeName: ROUTE_NAMES.FUNNEL_2FA,
  routeParams: [PURCHASE_UID, SHARE_UID],
  routeOptions: {},
};
const stepFunnelOrder2FA = {
  routeName: ROUTE_NAMES.FUNNEL_ORDER_2FA,
  routeParams: [ORDER_UID],
  routeOptions: {},
};
const stepFunnelInformationMissingPostCreation = {
  routeName: ROUTE_NAMES.FUNNEL_INFORMATION_MISSING_POST_CREATION,
  routeParams: [PURCHASE_UID, SHARE_UID],
  routeOptions: {},
};
const stepFunnelIdentification = {
  routeName: ROUTE_NAMES.FUNNEL_IDENTIFICATION,
  routeParams: [PURCHASE_UID, SHARE_UID],
  routeOptions: {},
};
const stepFunnelSummary = {
  routeName: ROUTE_NAMES.FUNNEL_SUMMARY,
  routeParams: [PURCHASE_UID, SHARE_UID],
  routeOptions: {},
};
export const stepFunnelCheckout = {
  routeName: ROUTE_NAMES.FUNNEL_CHECKOUT,
  routeParams: [PURCHASE_UID, SHARE_UID],
  routeOptions: {},
};
const stepFunnelPaymentMethodUpdate = {
  routeName: ROUTE_NAMES.FUNNEL_PAYMENT_METHOD_UPDATE,
  routeParams: [PURCHASE_UID, SHARE_UID],
  routeOptions: {},
};

const stepFunnelProcessing = {
  routeName: ROUTE_NAMES.FUNNEL_PROCESSING,
  routeParams: [PURCHASE_UID, SHARE_UID],
  routeOptions: { requireAuthTokenOnLoad: true },
};
const stepFunnelProcessingPostSignature = {
  routeName: ROUTE_NAMES.FUNNEL_PROCESSING_POST_SIGNATURE,
  routeParams: [PURCHASE_UID, SHARE_UID],
  routeOptions: { requireAuthTokenOnLoad: true },
};
const stepFunnelSuccess = {
  routeName: ROUTE_NAMES.FUNNEL_SUCCESS,
  routeParams: [SHARE_UID],
  routeOptions: {},
};
const stepFunnelOrderEntrypoint = {
  routeName: ROUTE_NAMES.FUNNEL_ORDER_ENTRYPOINT,
  routeParams: [],
  routeOptions: {},
};
export const stepFunnelOrderCheckout = {
  routeName: ROUTE_NAMES.FUNNEL_ORDER_CHECKOUT,
  routeParams: [ORDER_UID],
  routeOptions: {},
};
const stepFunnelOrderProcessing = {
  routeName: ROUTE_NAMES.FUNNEL_ORDER_PROCESSING,
  routeParams: [ORDER_UID],
  routeOptions: {},
};

/*
  Split funnel doesn't allow the user to fill missing informations
  It also has an extra step to let the leader fill pledgers information (email/phone)
*/
export const SplitFunnelSteps = [
  stepFunnelEntrypoint,
  stepFunnelInformationSplit,
  stepFunnelCheckout,
  stepFunnelProcessing,
  stepFunnelSuccess,
];

/*
  The default funnel steps
  For deferred, installment, down payment ...
*/
export const DefaultFunnelSteps = [
  stepFunnelEntrypoint,
  stepFunnelInformationMissing,
  stepFunnel2FA,
  stepFunnelRevenue,
  stepFunnelInformationMissingPostCreation,
  stepFunnelIdentification,
  stepFunnelCheckout,
  stepFunnelProcessing,
  stepFunnelSummary,
  stepFunnelProcessingPostSignature,
  stepFunnelSuccess,
];

export const PaymentMethodUpdateSteps = [
  stepFunnelPaymentMethodUpdate,
  stepFunnelProcessing,
];

/*
  Order funnel steps
  Implementation in progress, for now only these steps are working in CARD payment method
*/
export const OrderFunnelSteps = [
  stepFunnelOrderEntrypoint,
  stepFunnelOrder2FA,
  stepFunnelOrderCheckout,
  stepFunnelOrderProcessing,
  stepFunnelSuccess,
];
